// See: https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill

/* eslint-disable @typescript-eslint/no-explicit-any */

export function addCustomEventConstructorPolyfill(): void {
  if (typeof (window as any).CustomEvent === 'function') {
    return;
  }

  function CustomEvent(event: any, params: any): CustomEvent {
    params = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );

    // Yet another IE hack: For whatever reason, IE doesn't set the defaultPrevented property
    // when preventDefault() is called on custom events.
    // See https://stackoverflow.com/a/27675150
    (evt as any).__originalDefaultPreventedFn = evt.preventDefault;
    evt.preventDefault = function (): void {
      this.__originalDefaultPreventedFn();
      Object.defineProperty(this, 'defaultPrevented', {
        get: function (): boolean {
          return true;
        },
      });
    };
    return evt;
  }

  CustomEvent.prototype = Event.prototype;

  (window as any).CustomEvent = CustomEvent;
}
