import { addObjectPolyfills } from './object-util';
import { addStringPolyfills } from './string-util';
import { addCustomEventConstructorPolyfill } from './custom-event-constructor-polyfill';

/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';

addObjectPolyfills();
addStringPolyfills();
addCustomEventConstructorPolyfill();
